import React, { useEffect } from 'react';
import classnames from 'classnames';
import themeConfig from '../configs/themeConfig';

function FullPageLayout({ children }) {
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div
      className={classnames('full-layout wrapper bg-full-screen-image blank-page dark-layout', {
        'layout-dark': themeConfig.layoutDark,
      })}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="flexbox-container">
              <main className="main w-100">{children}</main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FullPageLayout;
