import axios from 'axios';
import { showAPILoading, hideAPILoading } from 'actions/api-actions';
import configs from '../configs/appConfig';
import { capitalizeFirstLetter } from '../utility/common-utils';

class BaseClient {
  client = axios;

  apiKey;

  constructor() {
    this.client = axios.create({
      timeout: 10000,
      baseURL: configs.baseUrl + configs.basePath,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.apiKey = configs.apiKey;
  }

  async handlePOST(path, body, config = {}) {
    return await this.client.post(path, body, config);
  }

  async handleGET(path, params, config = {}) {
    return await this.client.get(path, { params, ...config });
  }

  async handlePUT(path, body, config = {}) {
    return await this.client.put(path, body, config);
  }

  async handleDELETE(path, params, config = {}) {
    return await this.client.delete(path, { params, ...config });
  }

  async handleRequest({ method, path, data = {}, config = {} }) {
    try {
      showAPILoading();
      let response;
      switch (method.toUpperCase()) {
        case 'GET':
          response = await this.handleGET(path, data, config);
          break;
        case 'POST':
          response = await this.handlePOST(path, data, config);
          break;
        case 'PUT':
          response = await this.handlePUT(path, data, config);
          break;
        case 'DELETE':
          response = await this.handleDELETE(path, data, config);
          break;
        default:
          throw new Error('Method not implemented');
      }
      hideAPILoading();
      return response;
    } catch (err) {
      hideAPILoading();
      const { response } = err;
      if (response) {
        err.message = capitalizeFirstLetter(response.data.message);
      }

      throw err;
    }
  }

  setAuthorization(authorization) {
    this.client.defaults.headers.Authorization = `Bearer ${authorization}`;
  }

  unsetAuthorization() {
    if (this.checkAuthorization()) {
      delete this.client.defaults.headers.Authorization;
    }
  }

  checkAuthorization = () =>
    this.client.defaults.headers.Authorization !== '' && this.client.defaults.headers.Authorization !== undefined;
}

export default BaseClient;
