import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../../history';
import auth from './auth-reducer';
import app from './app-reducer';
import product from './product-reducers';

const reducers = combineReducers({
  router: connectRouter(history),
  auth,
  app,
  product,
});

export default reducers;
