export const mitra = [
  {
    id: 1,
    name: 'ABC',
    province: 'Jauh',
    salesAgent: 'Blm ada',
    status: 4,
    legality: 'PT',
    banned: false,
  },
  {
    id: 2,
    name: 'DEF',
    province: 'Dkt',
    salesAgent: 'Blm ada',
    status: 1,
    legality: 'Umum',
    banned: false,
  },
  {
    id: 3,
    name: 'GHI',
    province: 'Jauh dkt',
    salesAgent: 'Blm ada',
    status: 3,
    legality: 'PT',
    banned: false,
  },
  {
    id: 4,
    name: 'GHL',
    province: 'Jauh hampir',
    salesAgent: 'Blm ada',
    status: 2,
    legality: 'CV',
    banned: false,
  },
];

export const templateRelatedPlants = [
  {
    label: 'Tomat',
    value: 'tomato',
  },
  {
    label: 'Anggur',
    value: 'grape',
  },
  {
    label: 'Nanas',
    value: 'pineapple',
  },
  {
    label: 'Jambu',
    value: 'Guava',
  },
];
