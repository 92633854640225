import jwt from 'jsonwebtoken';
import { default as mock, token, route, routeSegments } from './mock';
import { jwtConfig } from './auth/authentication';

const personal = [
  {
    id: '8b48224c-d029-4076-a673-8e6c1bac2a84',
    fullName: 'Ellette Skipperbottom',
    email: 'eskipperbottom0@jugem.jp',
    createdAt: '14/02/2020',
    phone: '539-165-0914',
    home_address: 'Rumah Pribadi',
    province_id: 10031,
    province_name: 'DKI Jakarta',
    city_id: 1003173,
    city_name: 'Jakarta Pusat',
    sub_district: '',
    village: '',
    postal_code: 123456,
    address_line: '',
  },
  {
    id: 'e97a2720-203b-4914-8f4b-843f1b079190',
    fullName: 'Adriane Berns',
    email: 'aberns1@diigo.com',
    createdAt: '23/04/2020',
    phone: '379-660-9548',
    postal_code: 123456,
  },
  {
    id: 'a9aec092-b0e4-4146-b2c8-263191d524de',
    fullName: 'Melvin Saundercock',
    email: 'msaundercock2@ucoz.com',
    createdAt: '16/06/2020',
    phone: '825-943-8815',
    postal_code: 123456,
  },
  {
    id: '6359335c-62ac-48e1-bd74-51dae4765ccf',
    fullName: 'Rafaellle Facey',
    email: 'rfacey3@spotify.com',
    createdAt: '06/01/2020',
    phone: '241-974-9065',
    postal_code: 123456,
  },
  {
    id: '28151ea6-b6c0-4f09-9110-2925e3c0b4f2',
    fullName: 'Nels Peebles',
    email: 'npeebles4@tumblr.com',
    createdAt: '26/12/2019',
    phone: '100-938-4066',
    postal_code: 123456,
  },
  {
    id: '2ca71fea-3e94-4e06-92c7-c08c5543b513',
    fullName: 'Brucie Malser',
    email: 'bmalser5@dion.ne.jp',
    createdAt: '09/07/2020',
    phone: '189-220-1767',
    postal_code: 123456,
  },
  {
    id: 'a7c1358e-80c3-46a5-bb0b-be39204b7927',
    fullName: 'Edik Reignolds',
    email: 'ereignolds6@sphinn.com',
    createdAt: '25/06/2020',
    phone: '840-322-0649',
    postal_code: 123456,
  },
  {
    id: '601d8632-9bee-447d-91bf-464c22b54d1a',
    fullName: 'Jenna Kubatsch',
    email: 'jkubatsch7@sogou.com',
    createdAt: '30/12/2019',
    phone: '514-419-0152',
    postal_code: 123456,
  },
  {
    id: '434ebc10-51ca-449b-839c-73063b20f89b',
    fullName: 'Maurise Bown',
    email: 'mbown8@gizmodo.com',
    createdAt: '05/01/2020',
    phone: '642-912-0860',
    postal_code: 123456,
  },
  {
    id: 'a37835f9-3258-48c1-9b30-f03650459492',
    fullName: 'Janet Clohessy',
    email: 'jclohessy9@sfgate.com',
    createdAt: '11/04/2020',
    phone: '934-620-9456',
    postal_code: 123456,
  },
  {
    id: '9f6d1c95-0c9c-4f1e-987c-d81a19d18f31',
    fullName: 'Torr Itzak',
    email: 'titzaka@indiegogo.com',
    createdAt: '25/07/2020',
    phone: '140-470-9048',
    postal_code: 123456,
  },
  {
    id: '6c318755-2273-4a3f-818c-86517353306b',
    fullName: 'Ethelin Kroch',
    email: 'ekrochb@mtv.com',
    createdAt: '15/05/2020',
    phone: '202-118-8893',
    postal_code: 123456,
  },
  {
    id: 'c4677207-726e-4d57-8529-a10a3821536f',
    fullName: 'Brantley Schleicher',
    email: 'bschleicherc@blogger.com',
    createdAt: '05/08/2020',
    phone: '352-424-7977',
    postal_code: 123456,
  },
  {
    id: '335ac875-ba7b-4f57-ae77-b3d1c112b70a',
    fullName: 'Swen Bigg',
    email: 'sbiggd@patch.com',
    createdAt: '30/08/2020',
    phone: '515-400-4987',
    postal_code: 123456,
  },
  {
    id: '4e431383-e179-4b98-8729-2debf355157f',
    fullName: 'Niki Barttrum',
    email: 'nbarttrume@topsy.com',
    createdAt: '31/01/2020',
    phone: '756-567-7628',
    postal_code: 123456,
  },
  {
    id: 'a8fc719e-392e-479e-94ed-7cd7fac5b7e2',
    fullName: 'Carlin Yacobsohn',
    email: 'cyacobsohnf@amazon.co.uk',
    createdAt: '17/01/2020',
    phone: '668-777-6024',
    postal_code: 123456,
  },
  {
    id: '78c05445-06ed-4351-a991-037948c5c2fd',
    fullName: 'Gwenneth Karolyi',
    email: 'gkarolyig@mac.com',
    createdAt: '19/09/2020',
    phone: '218-598-2907',
    postal_code: 123456,
  },
  {
    id: '490131ca-f7fd-4167-9662-9bbc007da31e',
    fullName: 'Carolina Corrie',
    email: 'ccorrieh@posterous.com',
    createdAt: '21/08/2020',
    phone: '316-662-1148',
    postal_code: 123456,
  },
  {
    id: '2c12be55-ac35-4046-ae66-fb660f4403ff',
    fullName: 'Kipp Thews',
    email: 'kthewsi@lycos.com',
    createdAt: '31/10/2019',
    phone: '979-253-0628',
    postal_code: 123456,
  },
  {
    id: '5a8121d9-108f-47f3-ad8e-b2fff1222f8e',
    fullName: 'Fabiano Cater',
    email: 'fcaterj@chronoengine.com',
    createdAt: '12/04/2020',
    phone: '912-502-8726',
    postal_code: 123456,
  },
  {
    id: '818ea37e-7724-4ce8-b6b7-b732ce217c20',
    fullName: 'Honey Phillipp',
    email: 'hphillippk@nba.com',
    createdAt: '29/01/2020',
    phone: '863-212-7217',
    postal_code: 123456,
  },
  {
    id: '8f941f13-389e-471a-badd-d1d5cf0922a3',
    fullName: 'Jane Mounch',
    email: 'jmounchl@theguardian.com',
    createdAt: '14/02/2020',
    phone: '429-525-1925',
    postal_code: 123456,
  },
  {
    id: 'ebeff75e-4389-42a8-9a7f-7d8b0180081f',
    fullName: 'Phillip Farrens',
    email: 'pfarrensm@xrea.com',
    createdAt: '29/04/2020',
    phone: '706-255-6037',
    postal_code: 123456,
  },
  {
    id: '185cef21-c865-4552-b5b0-8d7c8faf7923',
    fullName: 'Farlie Tooher',
    email: 'ftoohern@bloomberg.com',
    createdAt: '12/09/2020',
    phone: '675-861-5814',
    postal_code: 123456,
  },
  {
    id: '2f991b35-85a9-44e1-ac06-1f857e97e4d4',
    fullName: 'Junette Faichnie',
    email: 'jfaichnieo@thetimes.co.uk',
    createdAt: '26/11/2019',
    phone: '522-168-9939',
    postal_code: 123456,
  },
  {
    id: '7fe9c7ed-947a-426e-9df2-6c96bf22b0ab',
    fullName: 'Mellisa Madill',
    email: 'mmadillp@usnews.com',
    createdAt: '14/06/2020',
    phone: '259-388-0844',
    postal_code: 123456,
  },
  {
    id: 'a41455bd-7204-4809-bcd5-0f5e651e80b3',
    fullName: 'Georg Scardifield',
    email: 'gscardifieldq@chronoengine.com',
    createdAt: '30/03/2020',
    phone: '739-928-1490',
    postal_code: 123456,
  },
  {
    id: 'e499da25-636c-438a-838b-1d0454751e99',
    fullName: 'Husein Stowe',
    email: 'hstower@java.com',
    createdAt: '17/09/2020',
    phone: '541-193-9822',
    postal_code: 123456,
  },
  {
    id: '10363f84-825b-4b6c-b0cb-ab31256f92ef',
    fullName: 'Guinna Wiltshaw',
    email: 'gwiltshaws@pen.io',
    createdAt: '01/01/2020',
    phone: '148-722-1527',
    postal_code: 123456,
  },
  {
    id: '2ef1fe6f-e26c-44b5-854e-1e575917fd2b',
    fullName: 'Giffy Mallaby',
    email: 'gmallabyt@tamu.edu',
    createdAt: '01/01/2020',
    phone: '196-501-5602',
    postal_code: 123456,
  },
  {
    id: '775c5bc8-bfa6-4ab0-a8ec-6788bfe4d5bb',
    fullName: 'Hercules Phillimore',
    email: 'hphillimoreu@va.gov',
    createdAt: '26/02/2020',
    phone: '660-706-2284',
    postal_code: 123456,
  },
  {
    id: 'd563094b-3e24-4d1f-a19b-bfd439a99e17',
    fullName: 'Marc Renak',
    email: 'mrenakv@1und1.de',
    createdAt: '10/07/2020',
    phone: '728-752-0428',
    postal_code: 123456,
  },
  {
    id: '4ce2223b-459a-4f84-a9ac-7b228c6835a4',
    fullName: 'Karna Strognell',
    email: 'kstrognellw@biblegateway.com',
    createdAt: '14/02/2020',
    phone: '921-272-5611',
    postal_code: 123456,
  },
  {
    id: '812801ba-dcf6-4630-858b-5603ec8703dc',
    fullName: 'Xenia Hinkes',
    email: 'xhinkesx@360.cn',
    createdAt: '10/10/2020',
    phone: '194-925-3517',
    postal_code: 123456,
  },
  {
    id: '036fdf8b-8906-4c99-998e-0ebc81bde010',
    fullName: 'Joanna Sagrott',
    email: 'jsagrotty@tinyurl.com',
    createdAt: '20/11/2019',
    phone: '684-106-0957',
    postal_code: 123456,
  },
  {
    id: '268eb8a2-0274-4618-9965-5bc290738c8e',
    fullName: 'Marcela Dyott',
    email: 'mdyottz@oracle.com',
    createdAt: '23/05/2020',
    phone: '415-773-5800',
    postal_code: 123456,
  },
  {
    id: 'cdfeadac-c81a-4757-8fe9-ff63c7da3653',
    fullName: 'Jorey Thomsson',
    email: 'jthomsson10@loc.gov',
    createdAt: '27/02/2020',
    phone: '921-673-5354',
    postal_code: 123456,
  },
  {
    id: '99e25d5c-07ec-4fce-bce0-cf5689ef7dd8',
    fullName: 'Jabez Yarnold',
    email: 'jyarnold11@ca.gov',
    createdAt: '06/10/2020',
    phone: '726-425-0746',
    postal_code: 123456,
  },
  {
    id: 'a3f02402-30f7-4f75-9ccf-b5fb09212797',
    fullName: 'Merrick Bust',
    email: 'mbust12@netvibes.com',
    createdAt: '28/11/2019',
    phone: '666-703-7568',
    postal_code: 123456,
  },
  {
    id: 'b853e445-4a7f-4121-9627-01aad92de35e',
    fullName: 'Stanfield Deniske',
    email: 'sdeniske13@e-recht24.de',
    createdAt: '11/10/2020',
    phone: '925-365-6990',
    postal_code: 123456,
  },
  {
    id: '6ebf200a-ca42-400c-b886-3d17e3079099',
    fullName: 'Karlens Caddock',
    email: 'kcaddock14@t.co',
    createdAt: '02/12/2019',
    phone: '362-362-8041',
    postal_code: 123456,
  },
  {
    id: '72440cc4-7bf6-4331-9acd-437a4d4a9ca9',
    fullName: 'Edyth Astridge',
    email: 'eastridge15@google.ru',
    createdAt: '14/04/2020',
    phone: '612-988-8542',
    postal_code: 123456,
  },
  {
    id: '42836f2a-783b-4983-8659-07b3b503c432',
    fullName: 'Burch Bristoe',
    email: 'bbristoe16@list-manage.com',
    createdAt: '22/09/2020',
    phone: '271-661-7567',
    postal_code: 123456,
  },
  {
    id: '23196a0c-fd87-4260-a6ec-44b329ce6915',
    fullName: 'Rollo Extance',
    email: 'rextance17@homestead.com',
    createdAt: '19/02/2020',
    phone: '604-275-8340',
    postal_code: 123456,
  },
  {
    id: 'd36db8cb-72af-4246-a153-7d5427f8c9ce',
    fullName: "Alyson O'Heyne",
    email: 'aoheyne18@icq.com',
    createdAt: '08/03/2020',
    phone: '974-269-9418',
    postal_code: 123456,
  },
  {
    id: '9f5313b2-3ada-4bb8-be54-2b091786ffc4',
    fullName: 'Erasmus Senter',
    email: 'esenter19@cbsnews.com',
    createdAt: '02/08/2020',
    phone: '817-816-8869',
    postal_code: 123456,
  },
  {
    id: '49afb0bd-2a7c-4e5e-98db-80fb08239053',
    fullName: 'Merline Pollins',
    email: 'mpollins1a@goo.ne.jp',
    createdAt: '17/08/2020',
    phone: '321-148-2936',
    postal_code: 123456,
  },
  {
    id: '7b2dc086-abf4-4dfc-97b2-b9c393adacce',
    fullName: 'Jeana True',
    email: 'jtrue1b@rediff.com',
    createdAt: '31/08/2020',
    phone: '939-184-1791',
    postal_code: 123456,
  },
  {
    id: '964d577a-9d66-4c04-aa7d-542bbebecb14',
    fullName: 'Emalia Marrion',
    email: 'emarrion1c@arstechnica.com',
    createdAt: '26/03/2020',
    phone: '143-763-0093',
    postal_code: 123456,
  },
  {
    id: '70ce2f98-d93d-45a4-af20-535b8744a234',
    fullName: 'Prisca Milhench',
    email: 'pmilhench1d@smh.com.au',
    createdAt: '07/08/2020',
    phone: '232-750-7181',
    postal_code: 123456,
  },
  {
    id: '34cc5600-8c62-453c-9266-d00b7b8e89f6',
    fullName: 'Amandie Paulton',
    email: 'apaulton1e@prlog.org',
    createdAt: '03/05/2020',
    phone: '408-904-1729',
    postal_code: 123456,
  },
  {
    id: '17ad5a28-5238-49d1-9b0f-02a23120c826',
    fullName: 'Flory Langran',
    email: 'flangran1f@google.es',
    createdAt: '10/02/2020',
    phone: '355-551-6906',
    postal_code: 123456,
  },
  {
    id: '323f2431-2dcc-4314-a0b8-6f7667e561b6',
    fullName: 'Flore Bosson',
    email: 'fbosson1g@state.tx.us',
    createdAt: '04/12/2019',
    phone: '400-420-3428',
    postal_code: 123456,
  },
  {
    id: 'f9992e15-901f-4e49-b44b-2da4d89b018e',
    fullName: 'Lanie Airdrie',
    email: 'lairdrie1h@nytimes.com',
    createdAt: '07/11/2019',
    phone: '876-288-8050',
    postal_code: 123456,
  },
  {
    id: 'dfde6b64-a92f-4f41-aa36-8e864640cf66',
    fullName: 'Atlanta Cardero',
    email: 'acardero1i@hostgator.com',
    createdAt: '16/06/2020',
    phone: '820-217-6431',
    postal_code: 123456,
  },
  {
    id: '55e079d4-5bcb-43f1-b5f6-45a2de68841f',
    fullName: 'Adaline Grimsdike',
    email: 'agrimsdike1j@tripadvisor.com',
    createdAt: '19/11/2019',
    phone: '582-865-8471',
    postal_code: 123456,
  },
  {
    id: 'db3a57e9-151c-4be3-98ff-0f0909f16440',
    fullName: 'Celene Spurrier',
    email: 'cspurrier1k@state.gov',
    createdAt: '08/12/2019',
    phone: '273-613-7927',
    postal_code: 123456,
  },
  {
    id: 'fedcdffc-04a6-4860-893a-a03104c3304b',
    fullName: 'Chen Ullock',
    email: 'cullock1l@technorati.com',
    createdAt: '12/11/2019',
    phone: '434-174-9796',
    postal_code: 123456,
  },
  {
    id: '9f8c1862-6af3-4e8f-83d7-74523f3a736a',
    fullName: 'Marie-ann Gustus',
    email: 'mgustus1m@taobao.com',
    createdAt: '29/12/2019',
    phone: '991-546-2935',
    postal_code: 123456,
  },
  {
    id: '3fa3fb98-dac2-4539-a79f-7b5cb51293c6',
    fullName: 'Petunia Cudde',
    email: 'pcudde1n@weibo.com',
    createdAt: '22/01/2020',
    phone: '812-604-5692',
    postal_code: 123456,
  },
  {
    id: 'a6ca4cae-da75-457b-9dcf-e63c249369ef',
    fullName: 'Tobie Vasyagin',
    email: 'tvasyagin1o@shop-pro.jp',
    createdAt: '12/06/2020',
    phone: '189-368-8792',
    postal_code: 123456,
  },
  {
    id: 'a7cba147-516a-4f74-8244-4a29d29bb925',
    fullName: 'Lura Vango',
    email: 'lvango1p@desdev.cn',
    createdAt: '26/05/2020',
    phone: '569-190-7533',
    postal_code: 123456,
  },
  {
    id: '7054983e-c68c-4fa2-8fe4-0704c1f9a7f0',
    fullName: 'Myrtia Stoakley',
    email: 'mstoakley1q@ftc.gov',
    createdAt: '03/07/2020',
    phone: '382-720-7227',
    postal_code: 123456,
  },
  {
    id: '93a2b088-dea4-469d-b122-3388b1a87f78',
    fullName: 'Jack Fairebrother',
    email: 'jfairebrother1r@usa.gov',
    createdAt: '04/06/2020',
    phone: '112-888-9734',
    postal_code: 123456,
  },
  {
    id: 'b3ec1e65-484a-4d0a-9c0f-cb6e19164820',
    fullName: 'Walt Sparham',
    email: 'wsparham1s@cocolog-nifty.com',
    createdAt: '14/11/2019',
    phone: '314-801-3286',
    postal_code: 123456,
  },
  {
    id: 'bef87874-2ffc-4a48-b297-acc3486d7e98',
    fullName: 'Elise Whinney',
    email: 'ewhinney1t@wordpress.com',
    createdAt: '04/02/2020',
    phone: '270-526-5231',
    postal_code: 123456,
  },
  {
    id: '8cc31bb3-bfde-41de-ae04-06457605943a',
    fullName: 'Emogene Duffell',
    email: 'eduffell1u@timesonline.co.uk',
    createdAt: '12/09/2020',
    phone: '192-540-0368',
    postal_code: 123456,
  },
  {
    id: '01bf474a-6b73-4aa4-9f5a-11f125c01721',
    fullName: 'Amie Cocher',
    email: 'acocher1v@sciencedaily.com',
    createdAt: '30/08/2020',
    phone: '657-163-6760',
    postal_code: 123456,
  },
  {
    id: 'dccbde42-a16e-4067-9495-3033ab5088a5',
    fullName: 'Bartram Windows',
    email: 'bwindows1w@stumbleupon.com',
    createdAt: '24/03/2020',
    phone: '702-470-9165',
    postal_code: 123456,
  },
  {
    id: 'd956f0f2-6fe2-4412-ba36-6bd7066913b9',
    fullName: 'Christie Myhill',
    email: 'cmyhill1x@kickstarter.com',
    createdAt: '29/07/2020',
    phone: '818-782-9026',
    postal_code: 123456,
  },
  {
    id: '645184c5-5924-4a02-a8de-123b5d595f24',
    fullName: 'Donna Berecloth',
    email: 'dberecloth1y@boston.com',
    createdAt: '20/03/2020',
    phone: '766-559-6949',
    postal_code: 123456,
  },
  {
    id: 'de8b95a8-57d0-4898-867b-ce3f50d3121e',
    fullName: "Ansley D'Errico",
    email: 'aderrico1z@google.com.br',
    createdAt: '23/05/2020',
    phone: '153-405-7589',
    postal_code: 123456,
  },
  {
    id: '909367b4-7ad0-40f7-95d9-17314cfbb9cd',
    fullName: 'Malena Zywicki',
    email: 'mzywicki20@mashable.com',
    createdAt: '09/12/2019',
    phone: '791-602-1158',
    postal_code: 123456,
  },
  {
    id: '14bc21d5-5c91-4d87-9b6e-35d16db40e2b',
    fullName: 'Vinson Angeau',
    email: 'vangeau21@51.la',
    createdAt: '24/01/2020',
    phone: '838-576-9815',
    postal_code: 123456,
  },
  {
    id: 'd0659239-4bdd-4d79-82b4-5c50437f15ac',
    fullName: 'Latisha Anwyl',
    email: 'lanwyl22@infoseek.co.jp',
    createdAt: '28/04/2020',
    phone: '422-515-0703',
    postal_code: 123456,
  },
  {
    id: '107b4721-9a1e-4106-979a-957ff9d538c5',
    fullName: 'Torey Gue',
    email: 'tgue23@reverbnation.com',
    createdAt: '12/08/2020',
    phone: '807-442-6789',
    postal_code: 123456,
  },
  {
    id: '6f819a02-f047-4c08-a5a0-8c6e183ab96b',
    fullName: 'Wallache Wank',
    email: 'wwank24@barnesandnoble.com',
    createdAt: '09/10/2020',
    phone: '769-882-8834',
    postal_code: 123456,
  },
  {
    id: '84824c71-755c-4fc4-a8cd-4165f3fed4d1',
    fullName: 'De Barrasse',
    email: 'dbarrasse25@stanford.edu',
    createdAt: '30/05/2020',
    phone: '528-908-6421',
    postal_code: 123456,
  },
  {
    id: 'bfa3a87a-66dd-4df7-a87b-30b23c02cf96',
    fullName: 'Ranna Shortland',
    email: 'rshortland26@huffingtonpost.com',
    createdAt: '18/08/2020',
    phone: '517-708-1145',
    postal_code: 123456,
  },
  {
    id: '3c54c721-4772-49ba-91dd-103dbfdb9dd7',
    fullName: 'Maighdiln Tompsett',
    email: 'mtompsett27@unblog.fr',
    createdAt: '21/10/2019',
    phone: '994-879-5695',
    postal_code: 123456,
  },
  {
    id: 'c769a19d-ed5c-4084-9e3e-770854597369',
    fullName: 'Pepito Marley',
    email: 'pmarley28@smugmug.com',
    createdAt: '23/09/2020',
    phone: '508-909-5552',
    postal_code: 123456,
  },
  {
    id: '538f526d-0b22-49c8-8e38-e928290645ab',
    fullName: 'Rena Allcorn',
    email: 'rallcorn29@deliciousdays.com',
    createdAt: '21/01/2020',
    phone: '206-338-0788',
    postal_code: 123456,
  },
  {
    id: 'd806d30c-8270-4a81-8481-3c996f3df2ee',
    fullName: 'Josepha Parncutt',
    email: 'jparncutt2a@psu.edu',
    createdAt: '28/07/2020',
    phone: '758-451-7757',
    postal_code: 123456,
  },
  {
    id: '16c280ca-8290-4aa5-96b6-fe56a8e6dfbf',
    fullName: 'Henrietta Pamment',
    email: 'hpamment2b@fastcompany.com',
    createdAt: '03/10/2020',
    phone: '698-571-4266',
    postal_code: 123456,
  },
  {
    id: '3175fa04-8b3a-4fbb-bbef-8c64f61af1ac',
    fullName: 'Lynea Olekhov',
    email: 'lolekhov2c@indiegogo.com',
    createdAt: '03/11/2019',
    phone: '107-379-9153',
    postal_code: 123456,
  },
  {
    id: '916b4600-ce2d-400d-9403-e919bc8dce3b',
    fullName: 'Catha McCord',
    email: 'cmccord2d@latimes.com',
    createdAt: '27/10/2019',
    phone: '114-210-3220',
    postal_code: 123456,
  },
  {
    id: '293bc95c-2b35-4591-8c6c-dd316032027c',
    fullName: 'Joni Wark',
    email: 'jwark2e@msu.edu',
    createdAt: '05/06/2020',
    phone: '214-189-8175',
    postal_code: 123456,
  },
  {
    id: '606054b6-327a-4a05-b18a-f68c5185e7bd',
    fullName: 'Silvan MacCrackan',
    email: 'smaccrackan2f@edublogs.org',
    createdAt: '04/07/2020',
    phone: '981-634-3901',
    postal_code: 123456,
  },
  {
    id: '4654ccbd-4e84-4050-aff7-ad518480c0a7',
    fullName: 'Glori Lebourn',
    email: 'glebourn2g@earthlink.net',
    createdAt: '10/04/2020',
    phone: '201-936-6935',
    postal_code: 123456,
  },
  {
    id: 'a662df40-dcd8-456f-8c67-1b334a952ea9',
    fullName: 'Alberik Juleff',
    email: 'ajuleff2h@yellowpages.com',
    createdAt: '22/12/2019',
    phone: '195-480-3882',
    postal_code: 123456,
  },
  {
    id: '785af667-35dc-4d3e-a476-7f393d17d09f',
    fullName: 'Nathanil Keller',
    email: 'nkeller2i@merriam-webster.com',
    createdAt: '26/04/2020',
    phone: '926-382-7988',
    postal_code: 123456,
  },
  {
    id: '0d9c0961-dfe8-47dc-923e-910d3b1f640e',
    fullName: 'Elton Garbutt',
    email: 'egarbutt2j@mapquest.com',
    createdAt: '30/12/2019',
    phone: '654-559-9638',
    postal_code: 123456,
  },
  {
    id: '8eb87e08-d010-4fb8-91ab-ae7bbaf87b21',
    fullName: 'Leshia Margeram',
    email: 'lmargeram2k@fda.gov',
    createdAt: '02/09/2020',
    phone: '297-534-1412',
    postal_code: 123456,
  },
  {
    id: '417c89d1-e358-41df-b20d-5324d5532f28',
    fullName: 'Julienne Valerius',
    email: 'jvalerius2l@aol.com',
    createdAt: '25/04/2020',
    phone: '241-628-7896',
    postal_code: 123456,
  },
  {
    id: 'c0b82671-8250-4fd4-9691-5a94fb30d6fa',
    fullName: 'Lucina Gartshore',
    email: 'lgartshore2m@wikimedia.org',
    createdAt: '03/09/2020',
    phone: '282-379-9620',
    postal_code: 123456,
  },
  {
    id: '1afcc5f6-c244-4136-8355-71a73bc1d79f',
    fullName: 'Elissa Canacott',
    email: 'ecanacott2n@tripod.com',
    createdAt: '23/06/2020',
    phone: '748-357-2322',
    postal_code: 123456,
  },
  {
    id: 'f999f056-8489-4830-9428-9eebb5f4c31c',
    fullName: 'Kellsie Le Fevre',
    email: 'kle2o@histats.com',
    createdAt: '17/08/2020',
    phone: '418-653-4288',
    postal_code: 123456,
  },
  {
    id: '5d4b2273-00d5-448b-9fa3-f5d918732589',
    fullName: 'Jacquelin Raddenbury',
    email: 'jraddenbury2p@skyrock.com',
    createdAt: '30/11/2019',
    phone: '923-823-8618',
    postal_code: 123456,
  },
  {
    id: 'f6f799fd-95da-4945-a4c5-dd00df39b0ea',
    fullName: 'Arlyn Brands',
    email: 'abrands2q@cloudflare.com',
    createdAt: '10/07/2020',
    phone: '367-256-9289',
    postal_code: 123456,
  },
  {
    id: '4c0d9c1a-0168-4bd8-9f2c-a38b72e559f8',
    fullName: 'Erinna Purnell',
    email: 'epurnell2r@example.com',
    createdAt: '14/08/2020',
    phone: '235-845-0995',
    postal_code: 123456,
  },
];

mock.onGet(route('/api/personal/:id')).reply((request) => {
  const { url } = request;
  const segment = routeSegments(url);

  try {
    jwt.verify(token(request), jwtConfig.secret);

    const response = {
      ...personal.find((o) => o.id === segment[1]),
    };

    if (Object.keys(response).length > 0) {
      return [200, response];
    }

    const error = 'Data not found';
    return [404, { error }];
  } catch (e) {
    const error = 'Invalid access token';
    return [401, { error }];
  }
});

mock.onGet(route('/api/admins/customers')).reply((request) => {
  try {
    jwt.verify(token(request), jwtConfig.secret);

    const response = {
      data: {
        customers: personal,
      },
    };

    return [200, response];
  } catch (e) {
    const error = 'Invalid access token';
    return [401, { error }];
  }
});
