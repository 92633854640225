module.exports = {
  SESSION_STORAGE: {
    CUSTOMER: 'Mestindo/eCommerce/customers',
    PARTNERS: 'Mestindo/eCommerce/partners',
    CHECKBOX_CUSTOMER: 'Mestindo/eCommerce/checkbox/customer',
    CHECKBOX_HISTORICAL: 'Mestindo/eCommerce/checkbox/historical',
    CHECKBOX_DOCUMENT: 'Mestindo/eCommerce/checkbox/document',
  },
  MITRA_STATUS: {
    WAITING_APPROVE: 1,
    APPROVED: 2,
    APPEAL: 3,
    REJECTED: 4,
    1: 'Waiting For Approval',
    2: 'Approved',
    3: 'Appeal',
    4: 'Rejected',
  },
  ADDRESS_TYPE: {
    PERSONAL: 1,
    SHOP: 2,
    WAREHOUSE: 3,
  },
  ASSET_TYPE: {
    CUSTOMER_FACE_PHOTO: 1,
    CUSTOMER_ID_CARD: 2,
    SHOP_PHOTO: 3,
    SHOP_LEGAL_DOCUMENT: 4,
    PRODUCT: 5,
    AVATAR: 6,
    PLANT_TYPE: 7,
    PLANT: 8,
    PLANT_ATTACHMENT: 9,
  },
  CUSTOMER_AUTH: {
    ACTIVE: 1,
    INACTIVE: 2,
    SUSPENDED: 3,
  },
  PARTNER_SUBMISSION: {
    Draft: 1,
    Submitted: 2,
    Approved: 3,
    Rejected: 4,
    Revised: 5,
    Canceled: 6,
  },
  PARTNER_BUSINESSES: {
    1: 'Kios Mandiri',
    2: 'CV',
    3: 'PT',
    KIOS_MANDIRI: 1,
    CV: 2,
    PT: 3,
  },
  MASTER_STATUS: {
    ACTIVE: 1,
    INACTIVE: 2,
  },
  PRODUCT_STATUS: {
    DRAFT: 1,
    PUBLISH: 2,
    UNPUBLISH: 3,
    1: 'Draft',
    2: 'Publish',
    3: 'Unpublished',
  },
  PRODUCT_BRANCH_STATUS: {
    SUBMITTED: 1,
    APPROVED: 2,
    SHOW: 3,
    HIDDEN: 4,
    1: 'Submitted',
    2: 'Approved',
    3: 'Show',
    4: 'Hidden',
  },
  SPECIFICATION_TYPE: {
    TEXT: 1,
    MULTI_SELECT: 2,
    1: 'Text',
    2: 'Opsi',
  },
  CATEGORY_LEVEL: {
    PARENT: 0,
    CHILD: 1,
  },
  TEXT_SPECIFICATION_DELIMITER: '*|',
  TEXT_SPECIFICATION_DELIMITER_REGEX: /[*/|]*$/gi,
  ADMIN_ROLE: {
    SUPER_ADMIN: 1,
    ADMIN: 2,
    SALES: 3,
    BRANCH_ADMIN: 4,
    1: 'Super Administrator',
    2: 'Administrator',
    3: 'Sales Wilayah',
    4: 'Admin Cabang',
  },
};
