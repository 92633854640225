import { AUTHENTICATED, GET_PROFILE, LOGOUT } from '../events';

const initialState = {
  auth: {
    authenticated: false,
    branch: {},
  },
  profile: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATED: {
      return {
        ...state,
        auth: action.payload,
      };
    }
    case GET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default auth;
