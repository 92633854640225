import jwt from 'jsonwebtoken';
import mock from '../mock';

const users = [
  {
    id: 1,
    username: 'demo@demo.com',
    password: 'demodemo',
    name: 'Demo',
    role: 'demo',
  },
  {
    id: 2,
    username: 'admin@admin.com',
    password: 'adminadmin',
    name: 'Admin',
    role: 'admin',
  },
  {
    id: 3,
    username: 'staff@staff.com',
    password: 'staff',
    name: 'Staff',
    role: 'staff',
  },
];

export const jwtConfig = {
  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  expireTime: 8000,
};

// POST: Check User Login Details and return user
mock.onPost('/api/admins/log-in').reply((request) => {
  const { username, password } = JSON.parse(request.data);
  let error = 'Something went wrong';

  const user = users.find((usr) => usr.username === username && usr.password === password);

  if (user) {
    try {
      const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime });

      const userData = { ...user };

      delete userData.password;

      const response = {
        user: userData,
      };

      return [200, response, { 'x-admin-access-token': accessToken, 'x-refresh-access-token': jwtConfig.expireTime }];
    } catch (e) {
      error = e;
    }
  } else {
    error = 'Email Or Password Invalid';
  }

  return [403, { error }];
});

// POST: Retrieve client token
mock.onPost('/api/auth/clients/admin').reply(() => {
  const accessToken = jwt.sign({ id: 'FAKE' }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime });
  const response = {
    user: {},
  };

  return [200, response, { 'x-client-token': accessToken, 'x-token-expired-at': 8000 }];
});

// POST: Check User Login Details and return user
mock.onDelete('/api/admins/log-out').reply((request) => {
  const { Authorization } = request.headers;
  const token = Authorization.split(' ')[1];
  try {
    jwt.verify(token, jwtConfig.secret);

    const response = {
      data: 'OK',
    };

    return [200, response];
  } catch (e) {
    const error = 'Invalid access token/already logged out';
    return [200, { error }];
  }
});
