export default class MasterClient {
  client;

  constructor(client) {
    this.client = client;
  }

  getSpecificationAttribute = async ({
    filterName,
    filterId,
    filterProductCategoryId,
    showLinkedProductCategory = false,
    showAll,
    limit,
    skip,
  }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/admins/products/specifications/attributes',
      data: { filterName, filterId, filterProductCategoryId, showLinkedProductCategory, showAll, limit, skip },
    });

    const { data } = response;
    return data;
  };

  addSpecificationAttribute = async ({ name, valueType, options = [], productCategoryId }) => {
    const response = await this.client.handleRequest({
      method: 'POST',
      path: 'admins/products/specifications/attributes',
      data: {
        name,
        valueType,
        values: {
          options,
        },
        productCategoryId,
      },
    });
    const { data } = response;
    return data;
  };

  linkSpecificationAttribute = async (productCategoryId, specificationAttributeId) => {
    const makeRequest = async () => {
      const _response = await this.client.handleRequest({
        method: 'PUT',
        path: `/admins/products/categories/specifications/link`,
        data: { productCategoryId, specificationAttributeId },
      });
      const { data } = _response;
      return data;
    };

    let response = await makeRequest();

    const {
      data: { linked },
    } = response;
    if (!linked) {
      response = await makeRequest();
    }

    return response;
  };

  unlinkSpecificationAttribute = async (productCategoryId, specificationAttributeId) => {
    const makeRequest = async () => {
      const _response = await this.client.handleRequest({
        method: 'PUT',
        path: `/admins/products/categories/specifications/link`,
        data: { productCategoryId, specificationAttributeId },
      });
      const { data } = _response;
      return data;
    };

    let response = await makeRequest();

    const {
      data: { linked },
    } = response;
    if (linked) {
      response = await makeRequest();
    }

    return response;
  };

  autoCompleteSpecificationAttribute = async ({ filterName, filterProductCategoryId, showLinkedProductCategory }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/admins/products/specifications/attributes/autocomplete',
      data: { filterName, filterProductCategoryId, showLinkedProductCategory },
    });

    const { data } = response;
    return data;
  };

  updateSpecificationAttribute = async (id, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/admins/products/specifications/attributes/${id}`,
      data: { data: updatedData, changelog, version },
    });

    const { data } = response;
    return data;
  };

  deleteSpecificationAttributes = async (id) => {
    const response = await this.client.handleRequest({
      method: 'DELETE',
      path: `/admins/products/specifications/attributes/${id}`,
    });

    const { data } = response;
    return data;
  };

  addBranch = async (branchData) => {
    const response = await this.client.handleRequest({ method: 'POST', path: '/admins/branches', data: branchData });

    const { data } = response;
    return data;
  };

  getBranches = async ({ skip, limit, sortBy, filterRegionName, showAll = false }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/admins/branches',
      data: {
        filterRegionName,
        skip,
        limit,
        sortBy,
        showAll,
      },
    });

    const { data } = response;
    return data;
  };

  getBranchDetail = async (xid) => {
    const response = await this.client.handleRequest({ method: 'GET', path: `/admins/branches/${xid}` });

    const { data } = response;
    return data;
  };

  updateBranch = async (xid, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/admins/branches/${xid}`,
      data: { data: updatedData, changelog, version },
    });

    const { data } = response;
    return data;
  };

  deleteBranch = async (xid) => {
    const response = await this.client.handleRequest({ method: 'DELETE', path: `/admins/branches/${xid}` });

    const { data } = response;
    return data;
  };

  createPlant = async (plantData) => {
    const response = await this.client.handleRequest({
      method: 'POST',
      path: '/contents/plants',
      data: plantData,
    });

    const { data } = response;
    return data;
  };

  getPlants = async ({ skip, limit, sortBy, filterName, showAll = false }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/contents/plants',
      data: {
        filterName,
        skip,
        limit,
        sortBy,
        showAll,
      },
    });

    const { data } = response;
    return data;
  };

  deletePlant = async (id) => {
    const response = await this.client.handleRequest({ method: 'DELETE', path: `/contents/plants/${id}` });

    const { data } = response;
    return data;
  };

  togglePlantHighlight = async (id, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/contents/plants/${id}/highlight`,
      data: { version },
    });

    const { data } = response;
    return data;
  };

  updatePlant = async (id, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/contents/plants/${id}`,
      data: { data: updatedData, changelog, version },
    });

    const { data } = response;
    return data;
  };

  getPlantDetail = async (id) => {
    const response = await this.client.handleRequest({ method: 'GET', path: `/contents/plants/${id}` });

    const { data } = response;
    return data;
  };

  getPlantTypes = async ({ skip, limit, sortBy, filterName, showAll = false }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/contents/plant-types',
      data: {
        filterName,
        skip,
        limit,
        sortBy,
        showAll,
      },
    });

    const { data } = response;
    return data;
  };

  createPlantType = async (plantTypeData) => {
    const response = await this.client.handleRequest({
      method: 'POST',
      path: '/contents/plant-types',
      data: plantTypeData,
    });

    const { data } = response;
    return data;
  };

  updatePlantType = async (id, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/contents/plant-types/${id}`,
      data: { data: updatedData, changelog, version },
    });

    const { data } = response;
    return data;
  };

  deletePlantType = async (id) => {
    const response = await this.client.handleRequest({ method: 'DELETE', path: `/contents/plant-types/${id}` });

    const { data } = response;
    return data;
  };

  getPlantTypeDetail = async (id) => {
    const response = await this.client.handleRequest({ method: 'GET', path: `/contents/plant-types/${id}` });

    const { data } = response;
    return data;
  };

  autoCompleteTag = async (slugName) => {
    const response = await this.client.handleRequest({
      method: 'get',
      path: '/contents/tags/autocomplete',
      data: { slugName },
    });

    const { data } = response;
    return data;
  };
}
