export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_ROUTE = 'UPDATE_ROUTE';

export const PRODUCT_DETAIL = 'PRODUCT_DETAIL';

export const API_CALL_START = 'API_CALL_START';
export const API_CALL_FINISH = 'API_CALL_FINISH';
