import { SHOW_MODAL, CLOSE_MODAL, UPDATE_ROUTE, API_CALL_START, API_CALL_FINISH } from '../events';

const initialState = {
  modal: {
    show: false,
    type: '',
    title: '',
    content: '',
    footer: '',
    onClose: undefined,
    persistent: false,
  },
  routeHistory: {
    past: '',
    current: '',
    needSuspend: false,
  },
  onAPICall: false,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, modal: { show: true, ...action.payload } };
    case CLOSE_MODAL:
      return { ...state, modal: { ...state.modal, show: false } };
    case UPDATE_ROUTE:
      return { ...state, routeHistory: { ...action.payload } };
    case API_CALL_START:
      return { ...state, onAPICall: true };
    case API_CALL_FINISH:
      return { ...state, onAPICall: false };
    default:
      return state;
  }
};

export default app;
