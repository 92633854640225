import React from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Dropdown } from 'reactstrap';
import * as Icon from 'react-feather';
import ReactCountryFlag from 'react-country-flag';
import { IntlContext } from '../../../utility/context/Internationalization';
import { logout, navigateTo } from '../../../redux/actions';
import userImg from '../../../assets/img/potrait/small/avatar-s-11.jpg';
import { ADMIN_ROLE } from '../../../constants';

const handleNavigation = (e, path) => {
  e.preventDefault();
  navigateTo(path);
};

const UserDropdown = ({ auth }) => {
  const { authenticated } = auth;
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#" onClick={(e) => handleNavigation(e, '/')}>
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        onClick={async (e) => {
          e.preventDefault();
          if (authenticated) {
            await logout();
          }
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  constructor(props) {
    super(props);
    const { profile } = props;

    this.state = {
      langDropdown: false,
      profile,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { profile } = this.props;
    const lenKeyProfile = Object.keys(profile).length;
    const lenKeyPastProfile = Object.keys(prevState.profile).length;
    if (lenKeyProfile !== lenKeyPastProfile) this.setProfile(profile);
  }

  setProfile = (profile) => this.setState({ profile });

  handleLangDropdown = () => {
    const { langDropdown } = this.state;
    this.setState({ langDropdown: !langDropdown });
  };

  render() {
    const { langDropdown, profile } = this.state;
    const { fullName, avatarUrl } = profile;
    const { auth } = this.props;

    const LanguangeDropdown = () => (
      <IntlContext.Consumer>
        {(context) => {
          const langArr = {
            en: 'English',
            id: 'Bahasa',
          };
          return (
            <Dropdown
              tag="li"
              className="dropdown-language nav-item"
              isOpen={langDropdown}
              toggle={this.handleLangDropdown}
              data-tour="language"
            >
              <DropdownToggle tag="a" className="nav-link">
                <ReactCountryFlag
                  className="country-flag"
                  countryCode={context.state.locale === 'en' ? 'us' : context.state.locale}
                  svg
                />
                <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                  {langArr[context.state.locale]}
                </span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag="a" onClick={() => context.switchLanguage('en')}>
                  <ReactCountryFlag className="country-flag" countryCode="us" svg />
                  <span className="ml-1">English</span>
                </DropdownItem>
                <DropdownItem tag="a" onClick={() => context.switchLanguage('id')}>
                  <ReactCountryFlag className="country-flag" countryCode="id" svg />
                  <span className="ml-1">Bahasa</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          );
        }}
      </IntlContext.Consumer>
    );

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <LanguangeDropdown />
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">{fullName || 'John Doe'}</span>
              <span className="user-status">{ADMIN_ROLE[auth.roleId]}</span>
            </div>
            <span data-tour="user">
              <img src={avatarUrl || userImg} className="round" height="40" width="40" alt="avatar" />
            </span>
          </DropdownToggle>
          <UserDropdown auth={auth} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

export default NavbarUser;
