import BaseClient from './base-client';
import AuthClient from './auth-client';
import PartnerClient from './partner-client';
import CustomerClient from './customer-client';
import CommonClient from './common-client';
import AdminClient from './admin-client';
import MasterClient from './master-client';
import ProductClient from './product-client';

export const client = new BaseClient();
export const authClient = new AuthClient(client);
export const partnerClient = new PartnerClient(client);
export const customerClient = new CustomerClient(client);
export const commonClient = new CommonClient(client);
export const adminClient = new AdminClient(client);
export const masterClient = new MasterClient(client);
export const productClient = new ProductClient(client);
