import { PRODUCT_DETAIL } from '../events';

const initialState = {
  data: {},
};

const product = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_DETAIL:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export default product;
