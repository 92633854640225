import { customAlphabet } from 'nanoid';
import crc32 from 'crc-32';

export function checkUndefined({ ...args }) {
  Object.keys(args).forEach((arg) => {
    if (args[arg] === undefined || args[arg] === '') throw new Error(`Missing this value ${arg}`);
  });
}

export const filterNullValue = (o) => {
  const isArray = Array.isArray(o);

  if (isArray) {
    const out = [];
    o.forEach((val) => {
      if (val === undefined || val === null) return;
      out.push(val);
    });

    return out;
  }
  return Object.keys(o).reduce((accum, curr) => {
    const val = o[curr];
    if (val === undefined || val === null) return accum;

    accum[curr] = val;
    return accum;
  }, {});
};

export function generateId(n = 6) {
  return customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', n)();
}

export function objectValToKey(obj) {
  Object.keys(obj).forEach((key) => {
    obj[obj[key]] = key;
  });
  return obj;
}

export function validateEmail(email, allowEmpty = false) {
  if (!email && allowEmpty) return;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isEmail = re.test(email);
  if (!isEmail) {
    throw new Error('Email not valid');
  }
}

export const getChangelogs = (formData) => {
  const changelogs = [];
  formData.forEach(({ key, value, changelogName, checksum }) => {
    if (checksum !== crc32.str(value ? value.toString() : '') && !changelogs.includes(changelogName || key))
      changelogs.push(changelogName || key);
  });
  return changelogs;
};

export const getCheckcum = (o) => {
  let target;
  switch (typeof o) {
    case 'object':
      target = JSON.stringify(o);
      break;
    case 'undefined':
      target = '';
      break;
    default:
      target = o.toString();
      break;
  }
  return crc32.str(target);
};

export const capitalizeFirstLetter = (w) => w.charAt(0).toUpperCase() + w.slice(1);

export const isJSON = (o) => o instanceof Object && !Array.isArray(o);

export const fromCurrency = (s) => s.replace(/\\s?|(\.*)/g, '');

export const toCurrency = (s) => {
  if (!s) return;
  const x = s.toString().replace(/[Aa-zZ]*$/gi, '');

  return x.replace(/\B(?=(\d{3})+(?!\d))|[^0-9]/g, '.');
};

export const generateImagePlaceholder = (width, height) =>
  `data:image/svg+xml;base64, ${window.btoa(
    `<svg height="${height}" width="${width}" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="${width}" height="${height}" fill="grey"/>
      </svg>`
  )}`;
