import jwt from 'jsonwebtoken';
import { default as mock, route, token } from './mock';
import { jwtConfig } from './auth/authentication';

const location = [
  {
    id: 100,
    name: 'Indonesia',
    type_id: 0,
    depth: 0,
  },
  {
    id: 10031,
    name: 'DKI Jakarta',
    type_id: 1,
    parent_id: 100,
    depth: 1,
  },
  {
    id: 10032,
    name: 'Jawa Barat',
    type_id: 1,
    parent_id: 100,
    depth: 1,
  },
  {
    id: 10036,
    name: 'Banten',
    type_id: 1,
    parent_id: 100,
    depth: 1,
  },
  {
    id: 1003101,
    name: 'Kepulauan Seribu',
    type_id: 3,
    parent_id: 10031,
    depth: 2,
  },
  {
    id: 1003171,
    name: 'Jakarta Selatan',
    type_id: 2,
    parent_id: 10031,
    depth: 2,
  },
  {
    id: 1003172,
    name: 'Jakarta Timur',
    type_id: 2,
    parent_id: 10031,
    depth: 2,
  },
  {
    id: 1003173,
    name: 'Jakarta Pusat',
    type_id: 2,
    parent_id: 10031,
    depth: 2,
  },
  {
    id: 1003174,
    name: 'Jakarta Barat',
    type_id: 2,
    parent_id: 10031,
    depth: 2,
  },
  {
    id: 1003201,
    name: 'Bogor',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003202,
    name: 'Sukabumi',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003203,
    name: 'Cianjur',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003204,
    name: 'Bandung',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003205,
    name: 'Garut',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003206,
    name: 'Tasikmalaya',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003207,
    name: 'Ciamis',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003208,
    name: 'Kuningan',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003209,
    name: 'Cirebon',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003210,
    name: 'Majalengka',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003211,
    name: 'Sumedang',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003212,
    name: 'Indramayu',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003213,
    name: 'Subang',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003214,
    name: 'Purwakarta',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003215,
    name: 'Karawang',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003216,
    name: 'Bekasi',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003217,
    name: 'Bandung Barat',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003218,
    name: 'Pangandaran',
    type_id: 3,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003175,
    name: 'Jakarta Utara',
    type_id: 2,
    parent_id: 10031,
    depth: 2,
  },
  {
    id: 1003271,
    name: 'Bogor',
    type_id: 2,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003272,
    name: 'Sukabumi',
    type_id: 2,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003273,
    name: 'Bandung',
    type_id: 2,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003274,
    name: 'Cirebon',
    type_id: 2,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003275,
    name: 'Bekasi',
    type_id: 2,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003276,
    name: 'Depok',
    type_id: 2,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003277,
    name: 'Cimahi',
    type_id: 2,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003278,
    name: 'Tasikmalaya',
    type_id: 2,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003279,
    name: 'Banjar',
    type_id: 2,
    parent_id: 10032,
    depth: 2,
  },
  {
    id: 1003601,
    name: 'Pandeglang',
    type_id: 3,
    parent_id: 10036,
    depth: 2,
  },
  {
    id: 1003602,
    name: 'Lebak',
    type_id: 3,
    parent_id: 10036,
    depth: 2,
  },
  {
    id: 1003603,
    name: 'Tangerang',
    type_id: 3,
    parent_id: 10036,
    depth: 2,
  },
  {
    id: 1003604,
    name: 'Serang',
    type_id: 3,
    parent_id: 10036,
    depth: 2,
  },
  {
    id: 1003671,
    name: 'Tangerang',
    type_id: 2,
    parent_id: 10036,
    depth: 2,
  },
  {
    id: 1003672,
    name: 'Cilegon',
    type_id: 2,
    parent_id: 10036,
    depth: 2,
  },
  {
    id: 1003673,
    name: 'Serang',
    type_id: 2,
    parent_id: 10036,
    depth: 2,
  },
  {
    id: 1003674,
    name: 'Tangerang Selatan',
    type_id: 2,
    parent_id: 10036,
    depth: 2,
  },
];

mock.onGet(route('/api/location/provinces')).reply((request) => {
  try {
    jwt.verify(token(request), jwtConfig.secret);

    const response = [...location.filter((o) => o.depth === 1)];

    if (Object.keys(response).length > 0) {
      return [200, response];
    }

    const error = 'Data not found';
    return [404, { error }];
  } catch (e) {
    const error = 'Invalid access token';
    return [401, { error }];
  }
});

mock.onGet(route('/api/location/cities')).reply((request) => {
  const {
    params: { parentId },
  } = request;
  try {
    jwt.verify(token(request), jwtConfig.secret);

    const response = [...location.filter((o) => (o.depth === 2 || o.depth === 3) && o.parent_id === parentId)];

    return [200, response];
  } catch (e) {
    const error = 'Invalid access token';
    return [401, { error }];
  }
});
