import React from 'react';
import { VerticalLayout, FullPageLayout } from '../../layouts';
import themeConfig from '../../configs/themeConfig';

const layouts = {
  vertical: VerticalLayout,
  full: FullPageLayout,
};

const ContextLayout = React.createContext(0);

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: themeConfig.direction,
    };
  }

  componentDidUpdate() {
    this.handleDirUpdate();
  }

  handleDirUpdate = () => {
    const { direction: dir } = this.state;
    if (dir === 'rtl') document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    else document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
  };

  render() {
    const { children } = this.props;
    return (
      <ContextLayout.Provider
        value={{
          state: this.state,
          fullLayout: layouts.full,
          verticalLayout: layouts.vertical,
        }}
      >
        {children}
      </ContextLayout.Provider>
    );
  }
}

export { Layout, ContextLayout };
