import { mitra as mitraDB } from '../@fake-db/db';

export default class PartnerClient {
  client;

  constructor(client) {
    this.client = client;
  }

  getMitra = async (status) => {
    let filterStatus;
    if (status instanceof Array) {
      filterStatus = status;
    } else {
      filterStatus = [status];
    }

    const mitra = mitraDB.filter((m) => filterStatus.includes(m.status));
    return { mitra };
  };

  getPartnerSubmissions = async ({ skip, limit, sortBy, keyword }) => {
    const response = await this.client.handleRequest({
      method: 'get',
      path: '/admins/partners/submissions',
      data: { skip, limit, sortBy, keyword, filterSubmissionStatusId: 2 },
    });
    const { data } = response;

    return data;
  };

  getRejectedPartnerSubmissions = async ({ skip, limit, sortBy, keyword }) => {
    const response = await this.client.handleRequest({
      method: 'get',
      path: '/admins/partners/submissions',
      data: { skip, limit, sortBy, keyword, filterSubmissionStatusId: 4 },
    });
    const { data } = response;
    return data;
  };

  getPartners = async ({ skip, limit, sortBy, keyword }) => {
    const response = await this.client.handleRequest({
      method: 'get',
      path: '/admins/partners',
      data: { skip, limit, sortBy, keyword },
    });
    const { data } = response;
    return data;
  };

  submitApproval = async (submissionXId, submissionStatusId, remarks) => {
    const response = await this.client.handleRequest({
      method: 'put',
      path: `/admins/partners/submissions/${submissionXId}/status`,
      data: {
        submissionStatusId: parseInt(submissionStatusId, 10),
        remarks,
      },
    });
    const { data } = response;
    return data;
  };

  getSubmissionDetail = async (submissionXId) => {
    const response = await this.client.handleRequest({
      method: 'get',
      path: `/admins/partners/submissions/${submissionXId}`,
    });

    const { data } = response;
    return data;
  };

  getPartnerDetail = async (partnerXId) => {
    const response = await this.client.handleRequest({
      method: 'get',
      path: `/admins/partners/${partnerXId}`,
    });

    const { data } = response;
    return data;
  };

  updatePartnerProfile = async (xid, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/admins/partners/${xid}`,
      data: {
        data: updatedData,
        changelog,
        version,
      },
    });

    const { data } = response;
    return data;
  };
}
