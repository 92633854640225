import { checkUndefined } from '../utility/common-utils';
import { getCookie, setCookie, isCookieEmpty, deleteCookie } from '../utility/browser-utils';
import { COOKIE, DEVICE_ID, DEVICE_PLATFORM_ID } from './constants';

const { APP_AUTH, CLIENT_AUTH, CLIENT_REFRESH, ROLE_ID, BRANCH } = COOKIE;

export default class AuthClient {
  client;

  constructor(client) {
    this.client = client;
  }

  login = async (username, password, needRemember = false) => {
    // Refresh application auth
    await this.refreshAppAuth();

    checkUndefined({ username, password });

    const response = await this.client.handleRequest({
      method: 'POST',
      path: '/admins/log-in',
      data: { username, password, metadata: { deviceId: DEVICE_ID, devicePlatformId: DEVICE_PLATFORM_ID } },
    });

    const { headers, data } = response;
    const clientAuth = headers['x-admin-access-token'];

    // Save role_id to cookie
    const {
      data: { roleId, branch },
    } = data;

    AuthClient.setCookie(headers, { needRemember, roleId, branch });

    this.client.setAuthorization(clientAuth);
    return data;
  };

  logout = async () => {
    await this.client.handleRequest({ method: 'DELETE', path: '/admins/log-out' });
  };

  clearSession = () => {
    AuthClient.clearClientSession();
    this.client.unsetAuthorization();
  };

  getProfile = async () => {
    if (!this.client.checkAuthorization()) {
      await this.refreshClientAuth();
    }

    const response = await this.client.handleRequest({ method: 'GET', path: '/admins/profile' });

    const { data } = response;

    data.data.profile.roleId = parseInt(getCookie(ROLE_ID), 10);

    return data;
  };

  authApp = async () => {
    this.client.unsetAuthorization();

    const response = await this.client.handleRequest({
      method: 'POST',
      path: '/auth/clients/admin',
      config: { headers: { authorization: `Basic ${this.client.apiKey}` } },
    });
    const { headers } = response;

    const authorization = headers['x-client-token'];
    const expiredAt = parseInt(headers['x-token-expired-at'], 10);

    if (!authorization || !expiredAt) throw new Error('App not authenticated');

    setCookie({ name: APP_AUTH, value: authorization, expiredAt });
    this.client.setAuthorization(authorization);
  };

  refreshAppAuth = async () => {
    const appToken = getCookie(APP_AUTH);

    if (!appToken) {
      return await this.authApp();
    }

    this.client.setAuthorization(appToken);
  };

  refreshClientAuth = async () => {
    if (isCookieEmpty()) throw new Error('ERR_COOKIE_EMPTY');
    // Get client token
    let clientToken = getCookie(CLIENT_AUTH);

    if (!clientToken) {
      // Get refresh token
      const refreshToken = getCookie(CLIENT_REFRESH);

      // Throw error if not exist
      if (!refreshToken) {
        this.clearSession();
        throw new Error('ERR_CLIENT_AUTH');
      }

      // Set client auth
      this.client.setAuthorization(refreshToken);

      // Get new token
      const response = await this.client.handleRequest({
        method: 'PUT',
        path: '/auth/admin/session',
        data: { device_id: DEVICE_ID, device_platform_id: DEVICE_PLATFORM_ID },
      });

      const { headers } = response;
      clientToken = headers['x-admin-access-token'];

      AuthClient.setCookie(headers, { needRemember: true });
    }

    this.client.setAuthorization(clientToken);

    // Get roleId
    const roleId = parseInt(getCookie(ROLE_ID), 10);
    if (!roleId) {
      this.clearSession();
      throw new Error('ERR_CLIENT_AUTH');
    }

    // Get branch
    let branch = getCookie(BRANCH);
    if (branch) {
      branch = JSON.parse(branch);
    }

    return { roleId, branch };
  };

  static setCookie = (headers = {}, options = { needRemember: false, roleId: undefined, branch: undefined }) => {
    const { needRemember, roleId, branch } = options;
    const clientAuth = headers['x-admin-access-token'];
    const clientAuthExp = parseInt(headers['x-token-expired-at'], 10);

    // Save client auth to cookie
    setCookie({ name: CLIENT_AUTH, value: clientAuth, expiredAt: clientAuthExp });

    if (needRemember) {
      const refreshToken = headers['x-refresh-access-token'];
      const refreshTokenExp = headers['x-refresh-access-token-expired-at'];

      // Save refresh token to cookie
      setCookie({ name: CLIENT_REFRESH, value: refreshToken, expiredAt: refreshTokenExp });
    }

    // Update role id lifetime
    setCookie({ name: ROLE_ID, value: roleId || getCookie(ROLE_ID), expiredAt: clientAuthExp });

    // Save branch
    setCookie({
      name: BRANCH,
      value: JSON.stringify(branch || getCookie(BRANCH)),
      expiredAt: clientAuthExp,
    });
  };

  static clearClientSession = () => {
    deleteCookie(CLIENT_AUTH, CLIENT_REFRESH, ROLE_ID, BRANCH);
  };
}
