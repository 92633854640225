import { timeNow, fromEpoch } from './date-utils';

export function setCookie({ name, value, lifetime, expiredAt }) {
  let later;
  // Default use lifetime
  if (lifetime) {
    const now = timeNow();
    const laterEpoch = now + lifetime;
    later = fromEpoch(laterEpoch);
  } else {
    later = fromEpoch(expiredAt);
  }
  const expires = `expires=${later.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      const value = c.substring(name.length, c.length);
      if (value === 'undefined' || value === 'null') return undefined;

      return value;
    }
  }
  return undefined;
}

export function deleteAllCookie() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }
}

export function deleteCookie(...names) {
  names.forEach((name) => {
    const value = getCookie(name);
    document.cookie = `${name}=${value};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  });
}

export const isCookieEmpty = () => document.cookie === '' || document.cookie === undefined;
