import MockAdapter from 'axios-mock-adapter';
import { client } from '../clients';

const mock = new MockAdapter(client.client);

export const token = (request) => {
  const {
    headers: { Authorization },
  } = request;

  return Authorization.split(' ')[1];
};
export const route = (path) => (typeof path === 'string' ? new RegExp(path.replace(/(:\w+)/g, '[^/]+')) : path);
export const routeSegments = (url) => url.split('/').slice(1);

export default mock;
