import store from '../store';
import { AUTHENTICATED, LOGOUT, GET_PROFILE } from '../events';
import { authClient } from '../../clients';

const { dispatch } = store;

export const login = async (email, password, remember) => {
  const response = await authClient.login(email, password, remember);

  const {
    data: { roleId, branch },
  } = response;

  dispatch({
    type: AUTHENTICATED,
    payload: { authenticated: true, roleId, branch },
  });
};

export const getProfile = async () => {
  const response = await authClient.getProfile();

  const {
    data: { profile },
  } = response;
  dispatch({
    type: GET_PROFILE,
    payload: profile,
  });
};

export const logout = async () => {
  try {
    await authClient.logout();
  } catch (err) {
    console.error(err);
  }

  authClient.clearSession();
  dispatch({ type: LOGOUT });
};

export const refreshAuth = async () => {
  try {
    const { roleId, branch } = await authClient.refreshClientAuth();

    dispatch({
      type: AUTHENTICATED,
      payload: { authenticated: true, roleId, branch },
    });
  } catch (err) {
    switch (err.message) {
      case 'ERR_CLIENT_AUTH':
      case 'ERR_COOKIE_EMPTY':
        dispatch({ type: LOGOUT });
        break;
      default:
        throw err;
    }
  }
};
