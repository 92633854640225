import { push, goBack, replace } from 'connected-react-router';
import { SHOW_MODAL, CLOSE_MODAL, UPDATE_ROUTE } from '../events';
import store from '../store';

const { dispatch } = store;

export const navigateTo = (page, useReplace = false) => {
  if (useReplace) dispatch(replace(page));
  else dispatch(push(page));
};

export const navigateBack = () => {
  dispatch(goBack());
};

export const showModal = (type, title, content, footer, options = { onClose: () => {}, persistent: false }) => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      type,
      title,
      content,
      footer,
      ...options,
    },
  });
};

export const closeModal = () => {
  dispatch({
    type: CLOSE_MODAL,
  });
};

export const updateRoute = (past, current, needSuspend = false) => {
  dispatch({
    type: UPDATE_ROUTE,
    payload: {
      past,
      current,
      needSuspend,
    },
  });
};
