import { PRODUCT_DETAIL } from '../events';
import { productClient } from '../../clients';
import store from '../store';

const { dispatch, getState } = store;

export const getProductDetail = async (xid, refresh = false) => {
  if (!refresh) {
    const {
      product: { data: _productData },
    } = getState();
    if (_productData.xid === xid) return _productData;
  }

  const response = await productClient.getProductDetail(xid);

  const { data } = response;

  dispatch({ type: PRODUCT_DETAIL, payload: data });
  return data;
};

export const updateProduct = async (xid, updatedData, changelog, version) => {
  const response = await productClient.updateProduct(xid, updatedData, changelog, version);

  const { data } = response;

  dispatch({ type: PRODUCT_DETAIL, payload: data });
  return data;
};

export const updateProductStatus = async (xid, statusId, version) => {
  const response = await productClient.updateProductStatus(xid, statusId, version);

  const { data } = response;

  dispatch({ type: PRODUCT_DETAIL, payload: data });
  return data;
};
