export default class ProductClient {
  client;

  constructor(client) {
    this.client = client;
  }

  getProductUnits = async ({ skip, limit, sortBy, showAll, filterId, filterName }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/admins/unit-types',
      data: { skip, limit, sortBy, showAll, filterId, filterName },
    });

    const { data } = response;
    return data;
  };

  addProductUnit = async (name, abbreviation) => {
    const response = await this.client.handleRequest({
      method: 'POST',
      path: '/admins/unit-types',
      data: { name, abbreviation },
    });

    const { data } = response;
    return data;
  };

  updateProductUnit = async (id, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/admins/unit-types/${id}`,
      data: { data: updatedData, changelog, version },
    });

    const { data } = response;
    return data;
  };

  deleteProductUnit = async (id) => {
    const response = await this.client.handleRequest({ method: 'DELETE', path: `/admins/unit-types/${id}` });

    const { data } = response;
    return data;
  };

  getProductBrands = async ({ skip, limit, sortBy, showAll, filterId, filterName }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/admins/brands',
      data: { skip, limit, sortBy, showAll, filterId, filterName },
    });

    const { data } = response;

    return data;
  };

  addProductBrand = async (name) => {
    const response = await this.client.handleRequest({
      method: 'POST',
      path: '/admins/brands',
      data: { name },
    });

    const { data } = response;
    return data;
  };

  updateProductBrand = async (id, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/admins/brands/${id}`,
      data: { data: updatedData, changelog, version },
    });

    const { data } = response;
    return data;
  };

  deleteProductBrand = async (id) => {
    const response = await this.client.handleRequest({ method: 'DELETE', path: `/admins/brands/${id}` });

    const { data } = response;
    return data;
  };

  autoCompleteProductBrand = async (filterName) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/admins/brands/autocomplete',
      data: { filterName },
    });

    const { data } = response;
    return data;
  };

  autoCompleteProductCategory = async (filterName, filterParentId) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/admins/products/categories/autocomplete',
      data: { filterName, filterParentId },
    });

    const { data } = response;
    return data;
  };

  autoCompleteProductUnits = async (filter) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/admins/unit-types/autocomplete',
      data: { filter },
    });

    const { data } = response;
    return data;
  };

  addProductCategory = async (name, { parentId, isChild = false }) => {
    if (isChild && parentId === undefined) throw new Error('Parent id cannot be undefined');

    const response = await this.client.handleRequest({
      method: 'POST',
      path: '/admins/products/categories',
      data: {
        name,
        parentId,
      },
    });

    const { data } = response;

    return data;
  };

  deleteProductCategory = async (id) => {
    const response = await this.client.handleRequest({
      method: 'DELETE',
      path: `/admins/products/categories/${id}`,
    });

    const { data } = response;

    return data;
  };

  updateProductCategories = async (id, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/admins/products/categories/${id}`,
      data: { data: updatedData, changelog, version },
    });

    const { data } = response;
    return data;
  };

  getProductCategories = async ({
    skip,
    limit,
    sortBy,
    showAll,
    filterId,
    filterName,
    filterLevel,
    filterParentId,
  }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/admins/products/categories',
      data: { skip, limit, sortBy, showAll, filterId, filterName, filterLevel, filterParentId },
    });

    const { data } = response;

    return data;
  };

  addProduct = async (productData) => {
    const response = await this.client.handleRequest({ method: 'POST', path: '/admins/products', data: productData });

    const { data } = response;
    return data;
  };

  getProducts = async ({ skip, limit, sortBy, filterStatusId, filterName, filterProductCategoryId, filterBrandId }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/admins/products',
      data: {
        filterName,
        filterStatusId,
        filterProductCategoryId,
        filterBrandId,
        skip,
        limit,
        sortBy,
      },
    });

    const { data } = response;
    return data;
  };

  deleteProduct = async (xid) => {
    const response = await this.client.handleRequest({ method: 'DELETE', path: `/admins/products/${xid}` });

    const { data } = response;
    return data;
  };

  getProductDetail = async (xid) => {
    const response = await this.client.handleRequest({ method: 'GET', path: `/admins/products/${xid}` });

    const { data } = response;
    return data;
  };

  updateProduct = async (xid, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/admins/products/${xid}`,
      data: { data: updatedData, changelog, version },
    });

    const { data } = response;
    return data;
  };

  updateProductStatus = async (xid, statusId, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/products/${xid}/status`,
      data: { statusId, version: parseInt(version, 10) },
    });

    const { data } = response;
    return data;
  };

  updateProductBranchStatus = async (xid, statusId, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/products/branches/${xid}/status`,
      data: { statusId, version },
    });

    const { data } = response;
    return data;
  };

  updateProductBranchPrice = async (xid, price, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/products/branches/${xid}/price`,
      data: { price, version },
    });

    const { data } = response;
    return data;
  };

  updateProductBranchStock = async (xid, stockBalance, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/products/branches/${xid}/stock`,
      data: { stockBalance, version },
    });

    const { data } = response;
    return data;
  };

  getAvailableBranches = async (productXId) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: `/products/${productXId}/branches/available`,
    });

    const { data } = response;
    return data;
  };

  createProductBranch = async (productBranchData) => {
    const response = await this.client.handleRequest({
      method: 'POST',
      path: '/products/branches',
      data: productBranchData,
    });

    const { data } = response;
    return data;
  };

  getProductBranches = async ({ skip, limit, sortBy, showAll, productXId, filterBranchXId }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: `/products/${productXId}/branches`,
      data: {
        filterBranchXId,
        skip,
        limit,
        sortBy,
        showAll,
      },
    });

    const { data } = response;
    return data;
  };

  getProductBranchDetail = async (productBranchXId) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: `/products/branches/${productBranchXId}`,
    });

    const { data } = response;
    return data;
  };
}
