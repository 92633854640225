import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import createDebounce from 'redux-debounced';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { history } from '../history';

const enhancers = [];
const middlewares = [thunk, createDebounce(), routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'admin'],
  blacklist: ['router'],
};

const persistedReducer = persistReducer(persistConfig, reducers);
const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);

const store = createStore(persistedReducer, {}, composedEnhancers);
const persistor = persistStore(store);

export default store;
export { persistor };
