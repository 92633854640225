export default class AdminClient {
  client;

  constructor(client) {
    this.client = client;
  }

  addCentralAdmin = async (adminData) => {
    const response = await this.client.handleRequest({
      method: 'POST',
      path: '/manage/admins/admin-pusat',
      data: adminData,
    });

    const { data } = response;
    return data;
  };

  getCentralAdmins = async ({ skip, limit, sortBy, filterFullName }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/manage/admins/admin-pusat',
      data: {
        filterFullName,
        skip,
        limit,
        sortBy,
      },
    });

    const { data } = response;
    return data;
  };

  getCentralAdminDetail = async (xid) => {
    const response = await this.client.handleRequest({ method: 'GET', path: `/manage/admins/admin-pusat/${xid}` });

    const { data } = response;
    return data;
  };

  updateCentralAdmin = async (xid, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/manage/admins/admin-pusat/${xid}`,
      data: { data: updatedData, changelog, version },
    });

    const { data } = response;
    return data;
  };

  deleteCentralAdmin = async (xid) => {
    const response = await this.client.handleRequest({ method: 'DELETE', path: `/manage/admins/admin-pusat/${xid}` });

    const { data } = response;
    return data;
  };

  addBranchAdmin = async (adminData) => {
    const response = await this.client.handleRequest({
      method: 'POST',
      path: '/manage/admins/admin-cabang',
      data: adminData,
    });

    const { data } = response;
    return data;
  };

  getBranchAdmins = async ({ skip, limit, sortBy, filterFullName }) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/manage/admins/admin-cabang',
      data: {
        filterFullName,
        skip,
        limit,
        sortBy,
      },
    });

    const { data } = response;
    return data;
  };

  getBranchAdminDetail = async (xid) => {
    const response = await this.client.handleRequest({ method: 'GET', path: `/manage/admins/admin-cabang/${xid}` });

    const { data } = response;
    return data;
  };

  updateBranchAdmin = async (xid, updatedData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'PUT',
      path: `/manage/admins/admin-cabang/${xid}`,
      data: { data: updatedData, changelog, version },
    });

    const { data } = response;
    return data;
  };

  deleteBranchAdmin = async (xid) => {
    const response = await this.client.handleRequest({ method: 'DELETE', path: `/manage/admins/admin-cabang/${xid}` });

    const { data } = response;
    return data;
  };
}
