import React, { useEffect } from 'react';
import { Navbar } from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { getProfile, showModal } from 'actions';
import NavbarBookmarks from './NavbarBookmarks';
import NavbarUser from './NavbarUser';

const ThemeNavbar = (props) => {
  const {
    auth,
    profile,
    navbarColor,
    navbarType,
    horizontal,
    sidebarVisibility,
    handleAppOverlay,
    changeCurrentLang,
  } = props;
  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark'];

  const _getProfile = async () => {
    await getProfile();
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const { authenticated } = auth;

      if (authenticated && Object.keys(profile).length === 0) {
        _getProfile()
          .then()
          .catch((err) => showModal('danger', 'Error', err.message));
      }
      isMounted = false;
    }
    return () => {
      isMounted = false;
    };
  }, [auth.authenticated]);

  return (
    <>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow floating-nav absolute-nav',
          {
            'navbar-light': navbarColor === 'default' || !colorsArr.includes(navbarColor),
            'navbar-dark': colorsArr.includes(navbarColor),
            'bg-primary': navbarColor === 'primary' && navbarType !== 'static',
            'bg-danger': navbarColor === 'danger' && navbarType !== 'static',
            'bg-success': navbarColor === 'success' && navbarType !== 'static',
            'bg-info': navbarColor === 'info' && navbarType !== 'static',
            'bg-warning': navbarColor === 'warning' && navbarType !== 'static',
            'bg-dark': navbarColor === 'dark' && navbarType !== 'static',
            'd-none': navbarType === 'hidden' && !horizontal,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div className="navbar-collapse d-flex justify-content-between align-items-center" id="navbar-mobile">
              <div className="bookmark-wrapper">
                <NavbarBookmarks sidebarVisibility={sidebarVisibility} handleAppOverlay={handleAppOverlay} />
              </div>
              {horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50" />
                  <h2 className="text-success mb-0">Mestindo</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={handleAppOverlay}
                changeCurrentLang={changeCurrentLang}
                profile={profile}
                auth={auth}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth: auth.auth,
  profile: auth.profile,
});

export default connect(mapStateToProps)(ThemeNavbar);
