import React from 'react';
import {
  Home,
  Users,
  UserPlus,
  UserCheck,
  User,
  UserX,
  Book,
  ShoppingBag,
  Award,
  Hash,
  List,
  Grid,
  Slack,
  Layers,
} from 'react-feather';
import { ADMIN_ROLE } from '../constants';

const navigationConfig = [
  {
    id: 'mainDash',
    title: 'Dashboard',
    type: 'item',
    icon: <Home size={20} />,
    navLink: '/',
  },
  {
    type: 'groupHeader',
    groupTitle: 'Customer',
  },
  {
    id: 'personal',
    title: 'Personal',
    type: 'item',
    icon: <User size={20} />,
    permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
    navLink: '/customers',
  },
  {
    id: 'mitra',
    title: 'Mitra',
    type: 'collapse',
    icon: <Users size={20} />,
    children: [
      {
        id: 'approved-partners',
        title: 'Pengajuan',
        type: 'item',
        icon: <UserPlus size={20} />,
        permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
        navLink: '/partners/approval',
      },
      {
        id: 'registered-partners',
        title: 'Terdaftar',
        type: 'item',
        icon: <UserCheck size={20} />,
        permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
        navLink: '/partners/registered',
      },
      {
        id: 'rejected-partners',
        title: 'Ditolak',
        type: 'item',
        icon: <UserX size={20} />,
        permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
        navLink: '/partners/rejected',
      },
    ],
  },
  {
    id: 'master',
    title: 'Master',
    type: 'collapse',
    children: [
      {
        id: 'category',
        title: 'Kategori',
        type: 'item',
        icon: <Book size={20} />,
        permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
        navLink: '/categories',
      },
      {
        id: 'products',
        title: 'Produk',
        type: 'item',
        icon: <ShoppingBag size={20} />,
        permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN, ADMIN_ROLE.BRANCH_ADMIN],
        navLink: '/products',
      },
      {
        id: 'brands',
        title: 'Brand',
        type: 'item',
        icon: <Award size={20} />,
        permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
        navLink: '/brands',
      },
      {
        id: 'units',
        title: 'Satuan',
        type: 'item',
        icon: <Hash size={20} />,
        permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
        navLink: '/units',
      },
      {
        id: 'specifications',
        title: 'Spesifikasi',
        type: 'item',
        icon: <List size={20} />,
        permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
        navLink: '/specifications',
      },
      {
        id: 'branches',
        title: 'Cabang',
        type: 'item',
        icon: <Grid size={20} />,
        permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
        navLink: '/branches',
      },
      {
        id: 'Tanaman',
        title: 'Tanaman',
        type: 'collapse',
        children: [
          {
            id: 'plants',
            title: 'Tanaman',
            type: 'item',
            icon: <Slack size={20} />,
            permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
            navLink: '/plants',
          },
          {
            id: 'plant-types',
            title: 'Jenis Tanaman',
            type: 'item',
            icon: <Layers size={20} />,
            permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
            navLink: '/plant-types',
          },
        ],
      },
    ],
  },
  {
    id: 'admin',
    title: 'Admin',
    type: 'collapse',
    children: [
      {
        id: 'central-admin',
        title: 'Admin Pusat',
        type: 'item',
        permissions: [ADMIN_ROLE.SUPER_ADMIN],
        navLink: '/central-admins',
      },
      {
        id: 'branch-admin',
        title: 'Admin Cabang',
        type: 'item',
        permissions: [ADMIN_ROLE.SUPER_ADMIN, ADMIN_ROLE.ADMIN],
        navLink: '/branch-admins',
      },
    ],
  },
];

export default navigationConfig;
