export default class CommonClient {
  client;

  constructor(client) {
    this.client = client;
  }

  getProvinces = async () => {
    const response = await this.client.handleRequest({ method: 'GET', path: '/commons/locations', data: { level: 2 } });
    const { data } = response;

    return data;
  };

  getCities = async (provinceXId) => {
    const response = await this.client.handleRequest({
      method: 'GET',
      path: '/commons/locations',
      data: { level: 3, parentXId: provinceXId },
    });
    const { data } = response;

    return data;
  };

  uploadFile = async (file, assetType) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('assetType', assetType);

    const response = await this.client.handleRequest({
      method: 'post',
      path: '/admins/assets',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    });

    const { data } = response;
    return data;
  };
}
