/* eslint-disable */
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Sidebar from './components/menu/vertical-menu/Sidebar';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

class VerticalLayout extends PureComponent {
  collapsedPaths = [];

  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      sidebarState: true,
      collapsedContent: false,
      sidebarHidden: false,
      currentLang: 'id',
      appOverlay: false,
    };
  }

  componentDidMount() {
    this.mounted = true;

    if (this.mounted) {
      if (window) {
        window.addEventListener('resize', this.updateWidth, false);
      }
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  updateWidth = () => {
    if (this.mounted) {
      this.setState(() => ({
        width: window.innerWidth,
      }));
    }
  };

  handleCollapsedMenuPaths = (item) => {
    const { collapsedPaths } = this;
    if (!collapsedPaths.includes(item)) {
      collapsedPaths.push(item);
      this.collapsedPaths = collapsedPaths;
    }
  };

  toggleSidebarMenu = () => {
    const { sidebarState, collapsedContent } = this.state;
    this.setState({
      sidebarState: !sidebarState,
      collapsedContent: !collapsedContent,
    });
  };

  sidebarMenuHover = (val) => {
    this.setState({
      sidebarState: val,
    });
  };

  handleSidebarVisibility = () => {
    if (this.mounted) {
      const { sidebarHidden } = this.state;
      if (window) {
        window.addEventListener('resize', () => {
          if (sidebarHidden) {
            this.setState({
              sidebarHidden: !sidebarHidden,
            });
          }
        });
      }
      this.setState({
        sidebarHidden: !sidebarHidden,
      });
    }
  };

  handleCurrentLanguage = (lang) => {
    this.setState({
      currentLang: lang,
    });
  };

  handleAppOverlay = (value) => {
    if (value.length > 0) {
      this.setState({
        appOverlay: true,
      });
    } else if (value.length < 0 || value === '') {
      this.setState({
        appOverlay: false,
      });
    }
  };

  handleAppOverlayClick = () => {
    this.setState({
      appOverlay: false,
    });
  };

  render() {
    const sidebarProps = {
      toggleSidebarMenu: this.props.collapseSidebar,
      toggle: this.toggleSidebarMenu,
      sidebarState: this.state.sidebarState,
      sidebarHover: this.sidebarMenuHover,
      sidebarVisibility: this.handleSidebarVisibility,
      visibilityState: this.state.sidebarHidden,
      activePath: this.props.match.path,
      collapsedMenuPaths: this.handleCollapsedMenuPaths,
      currentLang: this.state.currentLang,
      collapsed: this.state.collapsedContent,
      deviceWidth: this.state.width,
      activeTheme: 'success',
    };
    const navbarProps = {
      toggleSidebarMenu: this.toggleSidebarMenu,
      sidebarState: this.state.sidebarState,
      sidebarVisibility: this.handleSidebarVisibility,
      currentLang: this.state.currentLang,
      changeCurrentLang: this.handleCurrentLanguage,
      handleAppOverlay: this.handleAppOverlay,
      appOverlayState: this.state.appOverlay,
    };

    return (
      <div
        className={classnames(`wrapper vertical-layout theme-success`, {
          'menu-collapsed': this.state.collapsedContent === true && this.state.width >= 1200,
          'fixed-footer': false,
          'navbar-sticky': true,
          'theme-primary': false,
        })}
      >
        <Sidebar {...sidebarProps} />
        <div
          className={classnames('app-content content', {
            'show-overlay': this.state.appOverlay === true,
          })}
          onClick={this.handleAppOverlayClick}
        >
          <Navbar {...navbarProps} />
          <div className="content-wrapper">{this.props.children}</div>
        </div>

        <Footer />
        <div className="sidenav-overlay" onClick={this.handleSidebarVisibility} />
      </div>
    );
  }
}

export default VerticalLayout;
