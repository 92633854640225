import jwt from 'jsonwebtoken';
import mock from './mock';
import { jwtConfig } from './auth/authentication';
import { mitra } from './db';

mock.onGet('/api/mitra').reply((request) => {
  const { Authorization } = request.headers;
  const token = Authorization.split(' ')[1];
  try {
    jwt.verify(token, jwtConfig.secret);

    const response = {
      mitra,
    };

    return [200, response];
  } catch (e) {
    const error = 'Invalid access token';
    return [401, { error }];
  }
});
