export default class CustomerClient {
  client;

  constructor(client) {
    this.client = client;
  }

  getCustomers = async ({ filterFullName, limit, skip }) => {
    const response = await this.client.handleRequest({
      method: 'get',
      path: '/admins/customers',
      data: {
        limit,
        skip,
        filterFullName,
      },
    });
    const { data } = response;
    return data;
  };

  getAddresses = async (xid, addressTypeID) => {
    const response = await this.client.handleRequest({
      method: 'get',
      path: `/admins/customers/${xid}/addresses`,
      data: { filterAddressTypeId: addressTypeID, limit: '', skip: '', sortBy: '' },
    });
    const { data } = response;
    return data;
  };

  changeCustomerAuth = async (xid, status, version) => {
    const response = await this.client.handleRequest({
      method: 'put',
      path: `/admins/customers/${xid}/auth`,
      data: { status, version },
    });
    const { data } = response;
    return data;
  };

  updateProfile = async (customerXId, profileData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'put',
      path: `/admins/customers/${customerXId}`,
      data: {
        data: profileData,
        changelog,
        version,
      },
    });

    const { data } = response;
    return data;
  };

  getProfile = async (customerXId) => {
    const response = await this.client.handleRequest({
      method: 'get',
      path: `/admins/customers/${customerXId}`,
    });

    const { data } = response;
    return data;
  };

  updateAddress = async (addressXId, addressData, changelog, version) => {
    const response = await this.client.handleRequest({
      method: 'put',
      path: `/admins/customers/addresses/${addressXId}`,
      data: {
        data: addressData,
        changelog,
        version,
      },
    });

    const { data } = response;
    return data;
  };
}
