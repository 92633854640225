const {
  REACT_APP_API_USE_MOCK,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_API_BASE_URL,
  REACT_APP_API_BASE_PATH,
  REACT_APP_GOOGLE_API_KEY,
} = process.env;

const apiKey = Buffer.from(`${REACT_APP_CLIENT_ID}:${REACT_APP_CLIENT_SECRET}`).toString('base64');

const baseUrl = REACT_APP_API_USE_MOCK === 'true' ? '' : REACT_APP_API_BASE_URL;
const basePath = REACT_APP_API_USE_MOCK === 'true' ? '/api' : REACT_APP_API_BASE_PATH;
const configs = {
  baseUrl,
  basePath,
  apiKey,
  googleMapsApiKey: REACT_APP_GOOGLE_API_KEY,
};

module.exports = configs;
