import React from 'react';
import { IntlProvider } from 'react-intl';

import messageId from '../../assets/data/locales/id.json';
import messageEn from '../../assets/data/locales/en.json';

const menuMessage = {
  id: messageId,
  en: messageEn,
};

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      locale: 'id',
      messages: menuMessage.id,
    };
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: (language) => {
            this.setState({
              locale: language,
              messages: menuMessage[language],
            });
          },
        }}
      >
        <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale="id">
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
