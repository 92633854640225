const { REACT_APP_CLIENT_ID } = process.env;

module.exports = {
  DEVICE_ID: 'postman',
  DEVICE_PLATFORM_ID: 3,
  COOKIE: {
    APP_AUTH: `${REACT_APP_CLIENT_ID}_a_auth`,
    CLIENT_AUTH: `${REACT_APP_CLIENT_ID}_c_auth`,
    CLIENT_REFRESH: `${REACT_APP_CLIENT_ID}_c_r_auth`,
    ROLE_ID: `${REACT_APP_CLIENT_ID}_r_id`,
    BRANCH: `${REACT_APP_CLIENT_ID}_br`,
  },
};
